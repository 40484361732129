import React, { useState, useEffect } from "react";
import { Card, Space, Upload } from "lib";
import { Button, message, Modal, Descriptions } from "antd";
import { Layout } from "layout";
import { ClientGroupsClient, ClientGroupDto } from "@api";
import { showError } from "@action";
import { FormattedMessage } from "react-intl";
import ClientGroupDetailMenu from "@components/Admin/ClientGroup/ClientGroupDetailMenu";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";
import Header from "@components/Admin/ClientGroup/Header";
import ClientGroupCreateModal from "@components/Admin/ClientGroup/ClientGroupCreateModal";

interface Params {
  id: number;
}
interface Props {
  params: Params;
}

const GroupLevelGroupSettingPage = ({ params }: Props) => {
  const auth = new SubpageAuth(SECTION.Admin, "ClientGroups", "GroupSettings");
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const groupId = params.id;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();

  const [editModalVisible, setEditModalVisible] = useState(false);

  const clientGroupsClient = new ClientGroupsClient();

  const [state, setState] = useState({
    item: undefined as ClientGroupDto | undefined,
    loading: false,
  });

  async function fetchItems() {
    if (auth.cannot(ACTION.List, "GroupSettings")) return;

    setState({
      ...state,
      loading: true,
    });
    const res = await clientGroupsClient.getClientGroup(groupId);

    setState({
      ...state,
      item: res,
      loading: false,
    });
  }

  async function handleUploadClientLogo(id: number, f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await clientGroupsClient.uploadLogo(id, {
        data: f,
        fileName: f.name,
      });
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      fetchItems();
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  function OpenModalEditClientGroup() {
    setEditModalVisible(true);
  }

  async function handleEditClientGroup(data: any) {
    try {
      
      if(data.maxAccount === String("") ){
        data.maxAccount = null;
      }
      if(data.maxAccountForSub  === String("") ){
        data.maxAccountForSub = null;
      }
      const hide = messageApi.loading(
        <FormattedMessage id="StaticUpdatingMessage" />
      );
      const values = { ...data, id: state.item?.id };

      try {
        await clientGroupsClient.update(values.id, values);
        messageApi.success(
          <FormattedMessage id="StaticUpdateSuccessMessage" />
        );
        setEditModalVisible(false);

        await fetchItems();
      } catch (err) {
        showError(err);
      } finally {
        hide();
      }
    } catch (err) {
      /* form error block*/
    }
  }

  function handleCancelEditClientGroup() {
    setEditModalVisible(false);
  }

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="ClientGroupGroupSettingNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <Header id={groupId}></Header>
          <ClientGroupDetailMenu id={groupId} />
          <Space justify="space-evenly">
            <Descriptions
              contentStyle={{ width: "250px" }}
              column={{ xs: 1, sm: 2, md: 2 }}
              bordered
              extra={
                <Space>
                  {auth.can(ACTION.Edit, "GroupSettings") ? (
                    <Button
                      type="primary"
                      onClick={() => OpenModalEditClientGroup()}
                    >
                      <Space>
                        <FormattedMessage id="ClientGroupGroupSettingTableEditButton" />
                      </Space>
                    </Button>
                  ) : null}
                  {auth.can(ACTION.Upload, "ClientGroupLogo") ? (
                    <Upload
                      action={(f) =>
                        handleUploadClientLogo(state.item?.id as number, f)
                      }
                    >
                      <Button type="primary">
                        <FormattedMessage id="ClientGroupGroupSettingLogoUploadButton" />
                      </Button>
                    </Upload>
                  ) : null}
                </Space>
              }
            >
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticIdLabel" />
                }
                span={3}
              >
                {groupId}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticClientLabel" />
                }
                span={3}
              >
                {state.item?.clientName ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticCustomizeLanguageLabel" />
                }
              >
                {state.item?.canCustomizePreferredLanguage != undefined ? (
                  String(state.item?.canCustomizePreferredLanguage)
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticCustomizeEmailLabel" />
                }
              >
                {state.item?.canCustomizeEmail != undefined ? (
                  String(state.item?.canCustomizeEmail)
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticCustomizePasswordLabel" />
                }
              >
                {state.item?.canCustomizePassword != undefined ? (
                  String(state.item?.canCustomizePassword)
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticCustomizeFullNameLabel" />
                }
              >
                {state.item?.canCustomizeFullName != undefined ? (
                  String(state.item?.canCustomizeFullName)
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticCustomizeFullNameLabelLabel" />
                }
              >
                {state.item?.customFullNameLabel ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticReportAutoSendLabel" />
                }
              >
                {state.item?.isReportAutoSend != undefined ? (
                  String(state.item?.isReportAutoSend)
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticAccountExpiryLabel" />
                }
              >
                {state.item?.accountExpiry?.toLocaleString() ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticMaxAccountLabel" />
                }
              >
                {state.item?.maxAccount ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <FormattedMessage id="ClientGroupClientGroupStaticLanguageLabel" />
                }
                span={2}
              >
                {state.item?.preferredLanguageId ? (
                  <FormattedMessage id={state.item?.preferredLanguageId} />
                ) : (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={<FormattedMessage id="StaticCreatedLabel" />}
                span={2}
              >
                {state.item?.created?.toLocaleString() ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={<FormattedMessage id="StaticLastModifiedLabel" />}
                span={2}
              >
                {state.item?.lastModified?.toLocaleString() ?? (
                  <FormattedMessage id="StaticNaLabel" />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Space>
      </Card>
      <ClientGroupCreateModal
        visible={editModalVisible}
        handleAction={handleEditClientGroup}
        handleCancelAction={handleCancelEditClientGroup}
        clientGroup={state.item}
        titleId="ClientGroupGroupSettingModalEditTitle"
        okTextId="StaticUpdateButton"
      />
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default GroupLevelGroupSettingPage;
